import axios from 'axios';
const BASE_MAIN_URL = "https://matrimonial-backend.vercel.app/"
// const BASE_MAIN_URL = "http://localhost:5001/"
const token = localStorage.getItem('token');
const http = axios.create({
  baseURL: BASE_MAIN_URL,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

http.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default http;
