import http from './http_service';

const apiEndpoint = '/api/user';

const userApi = {
  signup: '/signup',
  login: '/login',
  profile: '/profile/',
  updateProfile: '/update-profile',
  verifiedUser: '/verified-user',
  addToFav: '/add-to-fav',
  getAllUsers: '/get-all-users',
  makeDisableOrEnable: '/make-disable-or-enable',
  changePassword: '/change-password',
};

export function signup(user) {
  return http.post(apiEndpoint + userApi.signup, user);
}

export function login(user) {
  return http.post(apiEndpoint + userApi.login, user);
}

export function profile(id) {
  return http.get(apiEndpoint + userApi.profile + id);
}

export function updateProfile(id, user) {
  return http.put(apiEndpoint + userApi.updateProfile + '/' + id, user, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function getActivatedUsers(id) {
  return http.get(apiEndpoint + userApi.verifiedUser + '/' + id);
}

export function addOrRemoveFavorite(id, favorite) {
  return http.put(apiEndpoint + userApi.addToFav + '/' + id,favorite );
}

export function getAllUsers() {
  return http.get(apiEndpoint + userApi.getAllUsers);
}

export function makeDisableOrEnable(id, isDisabled) {
  return http.put(apiEndpoint + userApi.makeDisableOrEnable + '/' + id, isDisabled,{
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export function changePassword(id, password) {
  return http.put(apiEndpoint + userApi.changePassword + '/' + id, password,{
    headers: {
      'Content-Type': 'application/json',
    },
  });
}