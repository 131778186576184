import { lazy } from "react";

const HomePage = lazy(() => import("./pages/HomePage"));
const LoginPage = lazy(() => import("./pages/LoginPage"));
const SignupPage = lazy(() => import("./pages/SignupPage"));
const AboutPage = lazy(() => import("./pages/AboutPage"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const Users = lazy(() => import("./pages/Users"));
const UserPage = lazy(() => import("./pages/UserPage"));
const PageNotFound = lazy(() => import("./pages/PageNotFound"));
const SearchPage = lazy(() => import("./pages/SearchPage"));

const GeneralDetails = lazy(() => import("./components/customer/GeneralDetails"));
const Settings = lazy(() => import("./components/customer/Settings"));
const OtherDetails = lazy(() => import("./components/customer/OtherDetails"));
const FavoriteList = lazy(() => import("./components/customer/FavoriteList"));
const CustomerDashboardDefault = lazy(() => import("./components/customer/CustomerDashboardDefault"));

const AllUsers = lazy(() => import("./components/admin/AllUsers"));
const AdminDashboardDefault = lazy(() => import("./components/admin/AdminDashboardDefault"));
const FeaturedList = lazy(() => import("./components/admin/FeaturedList"));
const AddUser = lazy(() => import("./components/admin/AddUser"));



export const ALL_LINKS = {
  LoginPage: {
    pageLink: "/login",
    view: LoginPage,
    loginRequire: false,
    showTo:{
      admin:true,
      tailor:true,
      customer:true
    }
  },
  SignupPage: {
    pageLink: "/signup",
    view: SignupPage,
    loginRequire: false,
    showTo:{
      admin:true,
      tailor:true,
      customer:true
    }
  },
  HomePage: {
    pageLink: "/",
    view: HomePage,
    loginRequire: false,
    showTo:{
      admin:true,
      tailor:true,
      customer:true
    }
  },
  AboutPage: {
    pageLink: "/about",
    view: AboutPage,
    loginRequire: false,
    showTo:{
      admin:true,
      tailor:true,
      customer:true
    }
  },
  Dashboard: {
    pageLink: "/dashboard/*",
    view: Dashboard,
    loginRequire: true,
    showTo:{
      admin:true,
      tailor:true,
      customer:true
    }
  },
  PrivacyPolicy: {
    pageLink: "/privacy_policy",
    view: PrivacyPolicy,
    loginRequire: false,
    showTo:{
      admin:true,
      tailor:true,
      customer:true
    }
  },
  Users: {
    pageLink: "/profiles",
    view: Users,
    loginRequire: false,
    showTo:{
      admin:true,
      tailor:true,
      customer:true
    }
  },
  UserPage: {
    pageLink: "/user",
    view: UserPage,
    loginRequire: false,
    showTo:{
      admin:true,
      tailor:true,
      customer:true
    }
  },
  PageNotFound: {
    pageLink: "*",
    view: PageNotFound,
    loginRequire: false,
    showTo:{
      admin:true,
      tailor:true,
      customer:true
    }
  },
  Search: {
    pageLink: "/search",
    view: SearchPage,
    loginRequire: true,
    showTo:{
      admin:true,
      tailor:true,
      customer:true
    }
  },
};

export const CUSTOMER_LINKS={
  CustomerDashboardDefault: {
    pageLink: "",
    view: CustomerDashboardDefault,
    loginRequire: true,
  },

  GeneralDetails: {
    pageLink: "general_details",
    view: GeneralDetails,
    loginRequire: true,
  },
  OtherDetails: {
    pageLink: "other_details",
    view: OtherDetails,
    loginRequire: true,
  },
  FavoriteList: {
    pageLink: "favorite_list",
    view: FavoriteList,
    loginRequire: true,
  },
  Settings: {
    pageLink: "settings",
    view: Settings,
    loginRequire: true,
  },
}


export const ADMIN_LINKS={
  AdminDashboardDefault: {
    pageLink: "",
    view: AdminDashboardDefault,
    loginRequire: true,
  },
  Users: {
    pageLink: "users",
    view: AllUsers,
    loginRequire: true,
  },
  FeaturedList: {
    pageLink: "featured_list",
    view: FeaturedList,
    loginRequire: true,
  },
  AddUser: {
    pageLink: "add_user",
    view: AddUser,
    loginRequire: true,
  },


}