import logo from './images/logo.png'
import m_hall from './images/m_hall.webp'

import bg_01 from './images/bg_01.webp'
import bg_03 from './images/bg_03.webp'


import success_2 from'./images/success/success_2.jpeg'
import success_3 from'./images/success/success_3.jpeg'
import success_4 from'./images/success/success_4.jpeg'
import success_5 from'./images/success/success_5.jpeg'
import success_6 from'./images/success/success_6.jpeg'
import success_7 from'./images/success/success_7.jpeg'
import success_8 from'./images/success/success_8.jpeg'
import success_9 from'./images/success/success_9.jpeg'
import success_10 from'./images/success/success_10.jpeg'
import success_11 from'./images/success/success_11.jpeg'
import success_12 from'./images/success/success_12.jpeg'
import success_13 from'./images/success/success_13.jpeg'
export const assets={
    logo,
    m_hall,
    bg_01,
    bg_03,
    success:[
    success_2,
    success_3,
    success_4,
    success_5,
    success_6,
    success_7,
    success_8,
    success_9,
    success_10,
    success_11,
    success_12,
    success_13
    ]
}