import { Suspense, useContext, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import MainDrawer from './components/menu/Drawer';
import { ALL_LINKS } from './constant';
import SplashScreen from './pages/SplashScreen';
import AuthContext from './store/AuthContext';
import 'react-toastify/dist/ReactToastify.css';
import {profile, updateProfile} from './services/userApi'
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from './store/userSlice';
import Footer from './components/menu/Footer';
import { WhatsApp } from '@mui/icons-material';
const App = () => {
  const authCtx = useContext(AuthContext);
  const dispatch = useDispatch();
  const isUpdated=useSelector(state=>state.user.isUpdated)
  const user=useSelector(state=>state.user.user)
  const PAGES = [
    ALL_LINKS.LoginPage,
    ALL_LINKS.HomePage,
    ALL_LINKS.AboutPage,
    ALL_LINKS.Dashboard,
    ALL_LINKS.PrivacyPolicy,
    ALL_LINKS.Users,
    ALL_LINKS.UserPage,
    ALL_LINKS.PageNotFound,
    ALL_LINKS.Search
  ];
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);


  useEffect(() => {
    if (authCtx.isLoggedIn) {
      profile(authCtx.userid)
      .then((res)=>{
        dispatch(userActions.setUserDetails(res.data.user));
      })
      .catch((err)=>{
        toast.error(err)
      })
    }
  }, [authCtx.isLoggedIn,isUpdated])


  return (
    <>
    <BrowserRouter>
      <div id="page-container">

          {loading ? (
            <SplashScreen />
          ) : (
            <>
            <div id="content-wrap" className="pb-[340px] mdrev:pb-[500px] relative">
              {!loading && <MainDrawer />}
              <Suspense fallback={<div />}>
                <Routes>
                  {PAGES.map((item, i) => {
                    return item.loginRequire ? (
                      <Route
                        key={i}
                        path={item.pageLink}
                        exact
                        element={
                          authCtx.isLoggedIn ? (
                            <item.view />
                          ) : (
                            <Navigate to={ALL_LINKS.LoginPage.pageLink} />
                          )
                        }
                      />
                    ) : (
                      <Route
                        key={i}
                        path={item.pageLink}
                        exact
                        element={<item.view />}
                      />
                    );
                  })}
                </Routes>

              </Suspense>
          <ToastContainer />

          {!loading && <Footer />}
        </div>

            </>
          )}
      </div>
    </BrowserRouter>
          </>
  );
};

export default App;
