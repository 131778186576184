import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from '@mui/icons-material/Menu'
import { ALL_LINKS, DATA } from "../../constant";
import { Link, useNavigate } from "react-router-dom";
import PersonIcon from '@mui/icons-material/Person'
import LoginIcon from '@mui/icons-material/Login'
import HomeIcon  from "@mui/icons-material/Home";
import MenuPopOver from "./MenuPopOver";
import { useContext } from "react";
import AuthContext from "../../store/AuthContext";
import { IconButton } from "@mui/material";
import { Checkroom, Favorite, FeaturedVideo, Group, Info, Logout, Person } from "@mui/icons-material";
import { useSelector } from "react-redux";
import DashboardIcon  from "@mui/icons-material/Dashboard";
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import GroupsIcon from '@mui/icons-material/Groups';
import { toast } from "react-toastify";
import { assets } from "../../assets";
export default function MainDrawer() {
  const navigate=useNavigate();
  const authCtx=useContext(AuthContext);
  const user=useSelector(state=>state.user.user);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const menu={
    home:{
      name:'Home',
      pageLink:ALL_LINKS.HomePage.pageLink,
      icon:<HomeIcon/>,
      showAlways:true
    },
    signup:{
      name:'Signup',
      pageLink:ALL_LINKS.SignupPage.pageLink,
      icon:<PersonIcon/>,
      showLoggedIn:false
    },
    login:{
      name:'Login',
      pageLink:ALL_LINKS.LoginPage.pageLink,
      icon:<LoginIcon/>,
      showLoggedIn:false
    },
    dashboard:{
      name:'Dashboard',
      pageLink:'dashboard/',
      icon:<DashboardIcon/>,
      showLoggedIn:false
    },
    genera_details:{
      name:'General Details',
      pageLink:'dashboard/general_details',
      icon:<Person/>,
      showLoggedIn:false
    },
    other_details:{
      name:'Other Details',
      pageLink:'dashboard/other_details',
      icon:<Info/>,
      showLoggedIn:false
    },
    favorite:{
      name:'Favorite',
      pageLink:'dashboard/favorite',
      icon:<Favorite/>,
      showLoggedIn:false
    },
    users:{
      name:'Profiles',
      pageLink:ALL_LINKS.Users.pageLink,
      icon:<Group/>,
      showLoggedIn:false
    },
    allusers:{
      name:'All Users',
      pageLink:'dashboard/users',
      icon:<Person/>,
      showLoggedIn:false
    },
    featuredList:{
      name:'Featured List',
      pageLink:'dashboard/featured_list',
      icon:<FeaturedVideo/>,
      showLoggedIn:false
    },
    addUser:{
      name:'Add User',
      pageLink:'dashboard/add_user',
      icon:<Person/>,
      showLoggedIn:false
    },
  }



  const showMenu={
    'all':[menu.home,menu.login,menu.users],
    'customer':[menu.home,menu.dashboard,menu.genera_details,menu.other_details,menu.users,menu.favorite],
    'admin':[menu.home,menu.allusers,menu.featuredList,menu.addUser],
  }


  let loadMenu=showMenu['all'];
  if(authCtx.isLoggedIn){
    loadMenu=showMenu[authCtx.role]
  }


  const list = (anchor) => {
    return(
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div id='Unbounded' className="p-2 flex justify-center  text-xl font-bold space-x-4 pt-4">
        Matrimonial
      </div>
      {authCtx.isLoggedIn &&
      <div className='flex flex-col items-center my-8'>
        <div className='bg-green-200 w-28 h-28  rounded-full relative overflow-hidden shadow-lg'>
          <img src={user.image} className='w-[100%] h-[100%]'/>
        </div>
        <h1 className='font-bold  mt-4'>{`${user.firstName} ${user.lastName}`}</h1>
        {user.mobileNo && <h1 className='font-semibold'>{`${user.mobileNo}`}</h1>}
      </div>
      }

      <List>
        {loadMenu.map((item, index) =>
          <Link to={item.pageLink} key={index}>
            <ListItemButton>
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </Link>
        )}
        {authCtx.isLoggedIn &&
          <ListItemButton onClick={()=>authCtx.logout()}>
              <ListItemIcon>
                <Logout/>
              </ListItemIcon>
              <ListItemText primary='Logout' />
            </ListItemButton>
         }
      </List>
    </Box>
  )};

  const MenuButton=({children,onClick})=>{
    return(
      <button className="w-32 text-black p-2 hover:cursor-pointer hover:bg-sec hover:text-white" onClick={onClick}>{children}</button>
    )
  }

  return (
    <div className=" ">
    <div className="w-[100%]  bg-[#f72151] text-white  p-4  flex  justify-between items-center m-auto">
       <IconButton onClick={()=>navigate(ALL_LINKS.HomePage.pageLink)}><img src={assets.logo} className='w-8 h-8' /></IconButton>
      <div className="flex space-x-2 smrev:space-x-1 items-center">

      {/* Menu Buttons */}
      <div className="mdrev:hidden flex justify-center items-center gap-8">
        <Link to={ALL_LINKS.HomePage.pageLink}><button className=" text-lg hover:underline">HOME</button></Link>
        {authCtx.isLoggedIn  && <Link to='/dashboard'><button className=" text-lg hover:underline">DASHBOARD</button></Link>}
        <Link to={ALL_LINKS.Users.pageLink}><button className=" text-lg hover:underline">PROFILES</button></Link>
        {/* <Link to={ALL_LINKS.Search.pageLink}><button className=" text-lg hover:underline">SEARCH</button></Link> */}
        {/* {!authCtx.isLoggedIn  && <Link to={ALL_LINKS.SignupPage.pageLink}><button className=" text-lg hover:underline">SIGNUP</button></Link>} */}
        {!authCtx.isLoggedIn  && <Link to={ALL_LINKS.LoginPage.pageLink}><button className=" text-lg hover:underline">LOGIN</button></Link>}

          {authCtx.isLoggedIn  && <button className="text-lg hover:underline" onClick={()=>authCtx.logout()}>LOGOUT</button>}
      </div>

      {/* Hamburger Menu */}
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <div className="hover:cursor-pointer md:hidden bg-black rounded-full p-2" onClick={toggleDrawer(anchor, true)}><MenuIcon sx={{color:'white'}}  fontSize='medium'/></div>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
      </div>
    </div>
    </div>
  );
}
