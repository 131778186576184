import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { IconButton } from "@mui/material";
import { Checkroom, Email, Facebook, Instagram, Language, Twitter, WhatsApp } from "@mui/icons-material";
import { assets } from '../../assets';
import { ALL_LINKS } from '../../constant';
import AuthContext from '../../store/AuthContext';

const Footer = () => {
    const authCtx=useContext(AuthContext);
    const menu=[
        {
            title:'Home',
            link:'/'
        },
    ]
    const currentYear=new Date().getFullYear();
  return (
    <div className=''>
    <div className='fixed bottom-0 right-0 flex justify-end z-10 '>
        <a rel="noreferrer" target='_blank' href={`https://wa.me/+917454970841`} className='font-bold bg-green-500 w-16 h-16 dark:mb-10 m-4 rounded-full flex justify-center items-center'>
      <WhatsApp sx={{color:'white'}} fontSize='large'/>
    </a>
    </div>
    <footer id='footer' className="absolute bottom-0 w-[100%]  p-4 bg-pri text-white   md:px-6 md:py-8">
    {/* <div className="sm:flex sm:items-center sm:justify-between">
        <Link to="/" className="flex items-center mb-4 sm:mb-0 gap-2 ">
       <IconButton sx={{background:'black'}}><img src={assets.logo} className='w-8 h-8' /></IconButton>

            <span className="self-center text-2xl font-semibold whitespace-nowrap ">ShubhVivah</span>
        </Link>
        <ul className="flex flex-wrap items-center mb-6 text-sm text-white sm:mb-0 ">
            {menu.map((item,i)=>
            <li key={i}>
                <Link to={item.link} className="mr-4 hover:underline md:mr-6 ">{item.title}</Link>
            </li>
            )}
        </ul>
    </div> */}
    <div id='Ubuntu' className=' py-8 grid grid-cols-3  gap-8 mdrev:grid-cols-1 text-white'>
        <div className='flex  flex-col items justify-center items-center   px-4'>
            <h4 className=' text-xl font-semibold'>Contact Info</h4>
            <h4 className=' font-semibold'>27,Dobhal Chowk Nehru Gram Dehradun</h4>
            <a href={`tel:+917454970841`} className='hover:underline  font-semibold'>+917454970841</a>
        </div>

        <div className='flex  flex-col  items-center px-4'>
        <h4 className=' text-xl font-semibold'>Quick Link</h4>

            <Link to={ALL_LINKS.HomePage.pageLink} className='hover:underline text-center font-semibold'>HOME</Link>
            <Link to={ALL_LINKS.Users.pageLink} className='hover:underline text-center font-semibold'>PROFILES</Link>
            {!authCtx.isLoggedIn && <Link to={ALL_LINKS.LoginPage.pageLink} className='hover:underline text-center font-semibold'>LOGIN</Link>}
        </div>

        <div className='flex  flex-col  items-center px-4'>
        <h4 className=' text-xl font-semibold'>Follow Us</h4>
        <div className='flex gap-2'>
            <IconButton><Facebook sx={{color:'white'}}/></IconButton>
            <IconButton><Instagram sx={{color:'white'}}/></IconButton>
            <IconButton><Twitter sx={{color:'white'}}/></IconButton>
        </div>

        </div>


    </div>
    <hr className="my-6 border-white sm:mx-auto  lg:my-8" />
    <span className="flex justify-center">Copyright © {currentYear} <Link href="/" className="hover:underline"> ShubhVivah</Link>. All Rights Reserved.</span>
    <div className="flex justify-center gap-2"><span>Developed by </span><a target='_blank' rel="noreferrer"  href="https://zeroxsoftwares.co.in" className="hover:underline">     Zerox Softwares</a> </div>

</footer>
</div>
  )
}

export default Footer