import React from 'react'
import { Checkroom } from '@mui/icons-material'
import { assets } from '../assets'
const SplashScreen = () => {
  return (
    <>
    <div className='w-[100vw] h-[100vh] flex justify-center items-center flex-col animate-bounce'>
      <img src={assets.logo} className='w-40 h-40' />
    </div>
    </>
  )
}

export default SplashScreen